import 'firebase/storage';
import 'firebase/analytics';

import { Routes } from 'client/enums';
import firebase from 'firebase/app';
import { init } from 'next-firebase-auth';

import { isProduction } from '../env';
import { getConfig } from './config';

export const initAuth = () => {
  const config = getConfig();

  init({
    authPageURL: Routes.LOGIN,
    appPageURL: '/',
    // uncomment for debug
    loginAPIEndpoint: '/api/auth/login',
    logoutAPIEndpoint: '/api/auth/logout',
    firebaseAdminInitConfig: {
      credential: {
        projectId: config.projectId,
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL || '',
        privateKey: process.env.FIREBASE_PRIVATE_KEY || '',
      },
      databaseURL: `https://${config.projectId}.firebaseio.com`,
    },
    firebaseClientInitConfig: {
      apiKey: process.env.FIREBASE_API_KEY || '',
      appId: config.appId,
      authDomain: `${config.projectId}.firebaseapp.com`,
      measurementId: config.measurementId,
      databaseURL: `https://${config.projectId}.firebaseio.com`,
      projectId: config.projectId,
      storageBucket: `${config.projectId}.appspot.com`,
    },
    cookies: {
      name: isProduction() ? 'ESGIntegrity' : 'ESGIntegrityDev',
      httpOnly: true,
      keys: [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        process.env.COOKIE_SECRET_CURRENT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: isProduction() ? true : false, // set this to false in local (non-HTTPS) development,
      signed: true,
    },
  });
};

export const initAnalytics = () => firebase.analytics();

export const getStorage = () => firebase.storage();
