import React, { FC, useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';

interface IToastProps {
  appearance: string;
  transitionDuration: number;
  transitionState: string;
  placement: string;
  onDismiss: () => void;
  headerText?: string;
}

const getTranslate = (placement): string => {
  const pos = placement.split('-');
  const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];
  const translateMap = {
    bottom: 'translate3d(0, 120%, 0)',
    left: 'translate3d(-120%, 0, 0)',
    right: 'translate3d(120%, 0, 0)',
    top: 'translate3d(0, -120%, 0)',
  };

  return translateMap[relevantPlacement];
};

const toastStates = (placement) => ({
  entered: { transform: 'translate3d(0,0,0)' },
  entering: { transform: getTranslate(placement) },
  exited: {
    opacity: 0,
    transform: 'scale(0.66)',
  },
  exiting: {
    opacity: 0,
    transform: 'scale(0.66)',
  },
});

const gutter = 8;

const appearanceToClasses = {
  error: 'bg-red-background border-red-error',
  info: 'bg-blue-background border-blue-primary',
  success: 'bg-green-background border-green-primary',
  warning: 'bg-orange-background border-orange-primary',
};

const appearanceToIcon = {
  error: '/icons/error.svg',
  info: '/icons/info.svg',
  success: '/icons/success.svg',
  warning: '/icons/warning.svg',
};

const appearanceToDefaultHeaderText = {
  error: 'Something went wrong',
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
};

export const Toast: FC<IToastProps> = ({
  children,
  appearance,
  transitionDuration,
  transitionState,
  placement,
  onDismiss,
  headerText,
}) => {
  const [height, setHeight] = useState<number | string>('auto');
  const elementRef = useRef(null);

  useEffect(() => {
    if (transitionState === 'entered') {
      const el = elementRef.current;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setHeight(el.offsetHeight + gutter);
    }

    if (transitionState === 'exiting') {
      setHeight(0);
    }
  }, [transitionState]);

  const bgClass = appearanceToClasses[appearance];

  return (
    <div
      data-cy={`toast-${appearance}`}
      ref={elementRef}
      style={{ transition: `height ${transitionDuration}ms`, height }}
    >
      <div
        className={`flex items-center shadow-md p-1 rounded mb-2 border-2 ${bgClass}`}
        style={{
          transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`,
          ...toastStates(placement)[transitionState],
        }}
      >
        <img
          alt=""
          src={appearanceToIcon[appearance]}
          style={{
            height: 64,
            width: 64,
            marginRight: 8,
          }}
        />
        <div className="mr-10" style={{ minWidth: 150 }}>
          <p className="text-2xl font-extrabold mb-1">
            {headerText || appearanceToDefaultHeaderText[appearance]}
          </p>
          <p className="text-sm">{children}</p>
        </div>
        <div
          className="rounded-md hover:bg-white cursor-pointer p-2 transition-colors"
          onClick={onDismiss}
        >
          <X className="text-primary" size={30} />
        </div>
      </div>
    </div>
  );
};
