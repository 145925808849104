import { isProduction } from 'shared/env';

export const devConfig = {
  appId: '1:839118822906:web:3e4db77357698816479843',
  measurementId: 'G-V67HCJC398',
  projectId: 'esg-integrity-dev',
};

export const prodConfig = {
  appId: '1:981219913973:web:e20925df4b3845a542d5bc',
  authDomain: 'esg-integrity-955ae.firebaseapp.com',
  measurementId: 'G-VXWTQQ68GQ',
  projectId: 'esg-integrity-955ae',
};

export const getConfig = () => (isProduction() ? prodConfig : devConfig);
