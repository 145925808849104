export enum Env {
  LOCALHOST = 'development',
  DEV = 'preview',
  PROD = 'production',
}

export const getEnv = (): Env => {
  if (process.env.VERCEL_ENV === Env.PROD) {
    return Env.PROD;
  }

  return (process.env.VERCEL_ENV as Env) || Env.DEV;
};

export const isLocalhost = (): boolean => getEnv() === Env.LOCALHOST;

export const isProduction = (): boolean => getEnv() === Env.PROD;

export const isServerSide = (): boolean => typeof window === 'undefined';

export const shouldConsoleLog = (): boolean =>
  process.env.CONSOLE_LOGGING === 'true';

export const isLocal = (): boolean =>
  !isServerSide() && window.location.hostname === 'localhost';

export const getAbsoluteUrl = (url: string): string =>
  isServerSide()
    ? `http://localhost:3000${url}`
    : `${window.location.origin}${url}`;
