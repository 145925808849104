export enum Routes {
  INDEX = '/',
  HOME = '/home',
  LOGIN = '/login',
  PENDING_APPROVAL = '/pending-approval',
  ERROR_404 = '/404',
  ADMIN = '/admin',
  COMPANY_PROFILE = '/company-profile',
  REPORTS = '/reports',
  APPENDIX = '/appendix',
  CREATE_COMPANY = '/create-company',
}

export const isRoute = (url: string | undefined, route: Routes) => {
  return url && url.includes(route);
};
