import 'client/css/global.css';
import 'client/css/fonts.css';
import 'client/css/final-report.css';
import 'client/css/text-styles.css';
import 'client/css/firebase-ui.css';
import 'zenscroll';

import { Toast } from 'client/components/generic/Toast';
import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { initAnalytics, initAuth } from 'shared/firebase';
import { DefaultSeo } from 'next-seo';
import { MetaData } from 'client/enums/meta';
import TagManager from 'react-gtm-module';

initAuth();

function App({ Component, pageProps }) {
  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-W553GMB',
    });
  }, []);

  return (
    <ToastProvider
      autoDismiss
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      components={{ Toast }}
      placement="bottom-center"
    >
      <DefaultSeo
        additionalLinkTags={[
          {
            rel: 'icon',
            href: MetaData.FAVICON,
          },
          {
            rel: 'shortcut ',
            href: MetaData.WEBCLIP,
          },
        ]}
        description={MetaData.DESCRIPTION}
        openGraph={{
          type: 'website',
          url: MetaData.URL,
          images: [
            {
              url: MetaData.OPEN_GRAPH,
              width: 1200,
              height: 680,
            },
          ],
        }}
        title={MetaData.TITLE}
      />
      <Component {...pageProps} />
    </ToastProvider>
  );
}

export default App;
