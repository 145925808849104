export enum Images {
  Logo = '/assets/logo.png',
  FCLogo = '/assets/full-color-logo.png',
  WhiteLogo = '/assets/white-logo.png',
  FILogo = '/assets/FI-version-logo.png',
  LogoLight = '/assets/esgiLogoWhite.png',
  LogoDark = '/assets/esgiLogoDark.png',
  CATLogo = '/assets/CAT-Logo.png',
  Chameleon = '/assets/chameleon-logo-white.png',
  Honeycomb = '/assets/ESG-BG.png',
  Error404 = '/assets/404.svg',
  Error500 = '/assets/500.svg',
  PendingApproval = '/assets/pending-approval.png',
  DesktopOnly = '/assets/desktop-only.png',
  LandingHero = '/assets/ESG-Hero.png',
  ReportHeaderAsset = '/assets/report-header-asset.svg',
  DecreasingEmissions = '/assets/Decreasing-Emmissions-Output.svg',
  EasyReporting = '/assets/Easy-Reporting.svg',
  GREETModeling = '/assets/GREET-modeling.svg',
  ImprovingFinancials = '/assets/Improving-Company-Finances.svg',
  DataEntry = '/assets/Piecewise-Data-Entry.svg',
  PublicPerception = '/assets/Shaping-Public-Perception.svg',
  NewHero = '/assets/ESG-Hero-new.svg',
  Jeff = '/assets/Jeff.png',
  Ryan = '/assets/Ryan.png',
  IntegratingSafety = '/final-report/integrating-safety.jpg',
  CharitableContributions = '/final-report/CharitableContributions.jpg',
  CommunityOutreach = '/final-report/CommunityOutreach.jpg',
  CyberSecurity = '/final-report/CyberSecurityAndFraudPrevention.jpg',
  Diversity = '/final-report/diversity.jpg',
  ElectricalVehicleCharging = '/final-report/ElectricVehicleCharging.jpg',
  EnvironmentalCover = '/final-report/EnvironmentalCover.jpg',
  ExternalAssurance = '/final-report/ExternalAssurances.jpg',
  ExtraPractices = '/final-report/extra-practices.jpg',
  FuelQualityAssurance = '/final-report/FuelQualityAssurance.jpg',
  GeneralInfo = '/final-report/Leadership.jpg',
  GreenhouseGasReduction = '/final-report/Greenhouse-Gas-Reductions.jpg',
  GovernanceCover = '/final-report/GovernanceCover.jpg',
  LeadershipStructure = '/final-report/LeadershipStructure.jpg',
  MainCover = '/final-report/MainCover.jpg',
  ManagementStructure = '/final-report/ManagementPrinciples.jpg',
  OtherEnvironmentalPolicies = '/final-report/OtherEnvironmentalPolicies.jpg',
  PetroleumReleasePrevention = '/final-report/PetroleumReleasePrevention.jpg',
  RobberiesAtFuelRetailers = '/final-report/robberies-at-fuel-retailers.jpg',
  SafetyTraining = '/final-report/CompanySafetyTrainingPrograms.jpg',
  SalaryRenumeration = '/final-report/salary-renumeration.jpg',
  SiteEfficiencies = '/final-report/SiteEfficiencies.jpg',
  SocialCover = '/final-report/SocialCover.jpg',
  StormRunoffManagement = '/final-report/StormRunoffManagement.jpg',
  SurveillanceCameras = '/final-report/surveillance-cameras.jpg',
  SustainabilityOfficer = '/final-report/SustainabilityOfficer.jpg',
  WorkforceAge = '/final-report/workforce-age.jpg',
  WorkforceGenderEthnicity = '/final-report/workforce-gender-ethnicity.jpg',
  WorkplaceSafety = '/final-report/WorkplaceHealthAndSafety.jpg',
}
