export enum MetaData {
  OPEN_GRAPH = '/meta/cat_opengraph.png',
  FAVICON = '/meta/cat_favicon.svg',
  WEBCLIP = '/meta/webclip.png',
  TITLE = 'Carbon Avoidance Tracker',
  DESCRIPTION = 'The Carbon Avoidance Tracker application helps fuel marketers and fleet operations improve their B2B emissions reporting.',
  URL = 'https://esgintegrity.app',
  REPORTS_TITLE = 'My Reports',
  REPORTS_DESCRIPTION = 'Create, edit, and view your ESG reports.',
  REPORTS_VIEW_OR_EDIT_TITLE = `{year} Report - {type}`,
  LOGIN_TITLE = 'Login',
  LOGIN_DESCRIPTION = "Login to your ESG Integrity account or Sign Up if you don't have one.",
  ADMIN_TITLE = 'Permissions',
  ADMIN_DESCRIPTION = 'Manage user permissions as an admin',
}

export const getViewOrEditReportMetaTitle = (
  year: number | null,
  type: 'Edit' | 'View'
) => {
  let title: string = MetaData.REPORTS_VIEW_OR_EDIT_TITLE;

  if (year) {
    title = title.replace('{year}', year.toString());
  } else {
    title = title.replace('{year} ', '');
  }

  title = title.replace('{type}', type);

  return title;
};
